<template>
  <div>
    <div class="mb-3 flex items-center justify-between border-b pb-3">
      <div>
        <h2 class="text-xl font-semibold">Thêm ban</h2>
      </div>
      <div class="">
        <router-link :to="{name: 'AdminRoleList'}"
                     class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          Danh sách ban
        </router-link>
      </div>
    </div>
    <form v-on:submit.prevent="createRole" class="lg:flex lg:space-x-10">
      <div class="lg:w-3/4">
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-3">
          <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
            <div class="col-span-2">
              <label for="name" class="font-semibold">Tên ban (*)</label>
              <input v-model="role.name" class="shadow-none with-border" id="name" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="slug" class="font-semibold">Slug (*)</label>
              <input v-model="role.slug" class="shadow-none with-border" id="slug" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="alias" class="font-semibold">Alias (*)</label>
              <input v-model="role.alias" class="shadow-none with-border" id="alias" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="description" class="font-semibold">Giới thiệu</label>
              <editor id="description" v-model:content="role.description"/>
            </div>

            <div class="col-span-2">
              <label for="position" class="font-semibold">Thứ tự</label>
              <input v-model="role.position" class="shadow-none with-border" id="position" min="0" type="number"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Đã bao gồm ban/nhóm trong tên?</label>
              <multiselect id="included_name" v-model="role.included_name"
                           :options="included_name_options"></multiselect>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Loại ban/nhóm?</label>
              <multiselect id="group_type" v-model="role.group_type" :options="['ban', 'nhóm']"></multiselect>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:w-1/4 w-full">
        <div class="mt-7">
          <button type="submit"
                  :disabled="disabled"
                  class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
            Thêm mới
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Editor from "../../Editor";
import {isAdmin, changePageTitle} from "../../../core/services/utils.service";
import ApiService from "../../../core/services/api.service";

export default {
  title: "AdminRoleEdit",
  components: {
    Editor
  },
  data() {
    return {
      role: {
        name: "",
        slug: "",
        alias: "",
        description: "",
        position: 0,
        included_name: null,
        group_type: ""
      },
      included_name_options: {
        1: "Đã bao gồm tên ban/nhóm",
        0: "Chưa bao gồm tên ban/nhóm"
      },
      disabled: false
    }
  },
  methods: {
    createRole() {
      if (!this.role.name) {
        this.$toast.error("Bạn chưa nhập tên ban");
        return;
      }

      this.disabled = true;

      let query = `mutation(
          $name: String!
          $slug: String!
          $alias: String! 
          $description: String 
          $position: Int
          $included_name: Int
          $group_type: String
        ) {
        createRole(input: {
          name: $name
          slug: $slug
          alias: $alias
          description: $description
          position: $position
          included_name: $included_name
          group_type: $group_type
        }) {
          id
        }
      }`;

      this.role.position = parseInt(this.role.position);
      this.role.included_name = parseInt(this.role.included_name);

      ApiService.graphql(query, this.role)
          .then(({data}) => {
            if (data.data && data.data.createRole) {
              this.$toast.success("Thêm ban/nhóm thành công");
              this.$router.push({name: "AdminRoleList"});
            } else {
              this.$toast.error(data.errors[0].message);
              this.disabled = false;
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
            this.disabled = false;
          });
    }
  },
  computed: {
    isAdmin() {
      return isAdmin();
    }
  },
  mounted() {
    if (!isAdmin()) {
      this.$router.push({name: "Home"});
    }

    changePageTitle("Thêm ban");
  }
}
</script>
